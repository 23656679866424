export default {
  methods: {
    getImageSize(image, size) {
      if (size === 1 || image.image_sizes === undefined || image.iae_format === 'svg') {
        return '_1'
      }
      if (size === 'bc') {
        if (image.image_sizes.some(x => x.identifier === '_bc')) {
          return '_bc'
        }
        size = 2
      }
      if (image.image_sizes.some(x => x.identifier === '_' + size)) {
        return '_' + size
      }
      return this.getImageSize(image, --size)
    },
  },
}
