<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Kategorie - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.cey_id"
        :columns="columns"
        :loading="loading"
        childrenColumnName="all_children"
        :dataSource="category"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
        :rowClassName="record => (record.all_children.length > 0 ? '' : 'expandable-hidden')"
      >
        <router-link
          slot="cey_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/ecommerce/category/detail/' + value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <span
          slot="thumb_image"
          slot-scope="record"
          :class="$style.thumbnail"
        >
          <img class="img-size-10" v-if="record !== null" :src="$uploadImagePath + record.iae_path + getImageSize(record, 10) + '.' + record.iae_format">
          <a-icon v-else theme="twoTone" twoToneColor="#ccc" type="frown" />
        </span>
        <span
          slot="active"
          slot-scope="value"
          :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
          <a-alert v-if="value === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span slot="action" slot-scope="record">
          <router-link :to="'/ecommerce/category/detail/'+record.cey_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.cey_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
                <p>Opravdu si přejete odstranit položku: #{{ record.cey_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ itemLength }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'

export default {
  mixins: [imageSizeMixin],
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      itemsLength: 0,
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/ecommerce/category/add',
          title: 'Přidat',
        },
        {
          icon: 'sort-ascending',
          theme: 'outlined',
          to: '/ecommerce/category/sort',
          title: 'Seřadit',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'cey_id',
          scopedSlots: {
            customRender: 'cey_id',
          },
        },
        {
          title: 'Obrázek',
          dataIndex: 'thumb_image',
          scopedSlots: {
            customRender: 'thumb_image',
          },
        },
        {
          title: 'Název',
          dataIndex: 'languages[0].pivot.cle_name',
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Zveřejněno',
          dataIndex: 'cey_is_active',
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    category: function () {
      return this.$store.getters['category/currentLanguage']
    },
    itemLength: function () {
      this.calculateItemsLength(this.category, 0)
      return this.itemsLength
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            if (this.selectedRowKeys.length === 0) {
              this.selectAllRows(this.category)
            } else {
              this.selectedRowKeys = []
            }
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    calculateItemsLength(items, itemsLength) {
      this.itemsLength = itemsLength
      items.forEach(value => {
        this.itemsLength++
        this.calculateItemsLength(value.all_children, this.itemsLength)
      })
    },
    selectAllRows(items) {
      items.forEach(value => {
        this.selectedRowKeys.push(value.cey_id)
        this.selectAllRows(value.all_children)
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('category/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(category) {
      this.loading = true
      this.$store.dispatch('category/delete', category)
        .catch(() => {})
        .then(() => {
          this.handleRefresh()
          this.selectedRowKeys = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.category.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
    this.$store.watch(
      (state, getters) => getters['language/active'], () => {
        if (this.$store.getters['language/active'] !== null && this.category.length === 0) {
          this.handleRefresh()
        }
      },
    )
  },
}
</script>

<style lang="scss" module scoped>
  @import "./style.module.scss";
</style>
